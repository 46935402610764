//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25BreakpointComponent } from "./s25.breakpoint.component";

@NgModule({
    declarations: [S25BreakpointComponent],
    imports: [CommonModule, FormsModule],
    providers: [S25BreakpointComponent],
    exports: [S25BreakpointComponent],
})
export class S25BreakpointModule {
    constructor() {}
}
