import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25BuildingComponent } from "./s25.building.component";
import { S25BuildingListComponent } from "./s25.building.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25AddressModule } from "../s25-address/s25.address.module";
import { S25ImageSelectorModule } from "../s25-image-selector/s25.image.selector.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25GenerateBuildingsComponent } from "./s25.generate.buildings.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
//import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
//import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";

@NgModule({
    declarations: [S25BuildingListComponent, S25BuildingComponent, S25GenerateBuildingsComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25TableModule,
        S25ModalModule,
        S25RichTextEditorModule,
        S25AddressModule,
        S25ImageSelectorModule,
        S25ImageSelectorModule,
        S25EditableModule,
        S25CheckboxModule,
        S25DropdownPaginatedModule,
        S25ToggleButtonModule,
        //S25MultiselectModule,
        //S25DropdownPaginatedModule
    ],
    providers: [S25BuildingListComponent, S25BuildingComponent, S25GenerateBuildingsComponent],
    exports: [S25BuildingListComponent, S25BuildingComponent, S25GenerateBuildingsComponent],
})
export class S25BuildingModule {
    constructor() {}
}
