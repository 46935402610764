import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../s25-table/Table";
import { CacheRepository } from "../../decorators/cache.decorator";
import { Building, BuildingService } from "../../services/building.service";
import { Bind } from "../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { ModalService } from "../modal/modal.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PermissionService } from "../../services/permission.service";

@TypeManagerDecorator("s25-ng-building-list")
@Component({
    selector: "s25-ng-building-list",
    template: `
        @if (isInit) {
            <div class="top">
                <!--<h4>Building Management</h4> -->
                <button class="aw-button aw-button--primary c-margin-bottom--half" (click)="onCreateClick()">
                    Create New
                </button>
                @if (hasBulkCreate) {
                    <p class="c-margin-bottom--half">
                        To create buildings using existing categories, custom attributes, or partitions,
                        <a href="#!/home/system/settings/master/definitions/buildings/bulkcreate">click here</a>
                    </p>
                }
                <s25-ng-table
                    [dataSource]="tableData"
                    [hasRefresh]="true"
                    [hasFilter]="true"
                    [columnSortable]="true"
                    [pivotThreshold]="800"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
})
export class S25BuildingListComponent implements OnInit {
    isInit = false;
    tableData: Table.DataSource;
    building: Building;
    buildings = new Map<number, Building>();
    hasBulkCreate = false;

    @ViewChild(S25TableComponent) table: S25TableComponent;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.hasBulkCreate = await PermissionService.canBulkCreateBuildings();
        this.tableData = this.getTableConfig();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    getTableConfig(): Table.DataSource {
        return {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "buildingName",
                    header: "Building Name",
                },
                {
                    id: "buildingCode",
                    header: "Building Code",
                },
                {
                    id: "latitude",
                    header: "Latitude",
                    minWidth: 70,
                },
                {
                    id: "longitude",
                    header: "Longitude",
                    minWidth: 90,
                },
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("BuildingService");
        let buildings = await BuildingService.getBuildings();
        buildings && buildings.items ? (buildings = buildings.items) : (buildings = []);
        for (let building of buildings) this.buildings.set(building.id, building);
        return {
            rows: buildings.map(this.mapToRow) || [],
        };
    }

    mapToRow(item: Building): Table.Row {
        let latitudeString: string = "";
        let longitudeString: string = "";
        if (item.latitude !== undefined) latitudeString = item.latitude.toFixed(5).toString(); //ANG-4824
        if (item.longitude !== undefined) longitudeString = item.longitude.toFixed(5).toString(); //ANG-4824
        return {
            id: item.id,
            name: item.buildingName,
            cells: {
                buildingName: { text: item.buildingName },
                buildingCode: { text: item.buildingCode },
                latitude: { text: latitudeString },
                longitude: { text: longitudeString },
            },
        };
    }

    @Bind
    async refresh(val: boolean) {
        val && (await this.table.refresh(true));
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this building?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        await BuildingService.deleteBuilding(row.id as number).catch(this.error);
        return this.table.refresh();
    }

    @Bind
    onEditClick(row: Table.Row) {
        ModalService.modal("edit-building", {
            building: this.buildings.get(row.id as number),
            title: `Edit Building: ${row.name}`,
            onSave: this.table.refresh,
        });
    }

    onCreateClick() {
        ModalService.modal("edit-building", {
            building: {
                id: 0,
                buildingName: "",
                buildingCode: "",
                description: "",
                address: "",
                city: "",
                state: "",
                zipPostCode: "",
                country: "",
            },
            title: `Create Building`,
            onSave: this.table.refresh,
        });
    }

    error(error: any) {
        S25Util.showError(error);
    }
}
