import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { Building, BuildingService } from "../../services/building.service";
import { Bind } from "../../decorators/bind.decorator";
import { AddressI } from "../../pojo/AddressI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-building")
@Component({
    selector: "s25-ng-building",
    template: `
        @if (init) {
            <div>
                <div class="buildingRow">
                    <label class="ngBold" for="building_name">Building Name:</label>
                    <input
                        [(ngModel)]="building.buildingName"
                        id="building-name"
                        class="c-input"
                        name="building-name"
                        type="text"
                        minlength="2"
                        maxlength="80"
                        required
                    />
                </div>
                <div class="buildingRow">
                    <label class="ngBold" for="building_code">Building Code:</label>
                    <input
                        [(ngModel)]="building.buildingCode"
                        id="building-code"
                        class="c-input"
                        name="building-code"
                        type="text"
                        minlength="2"
                        maxlength="20"
                    />
                </div>
                <div class="buildingRow">
                    <label class="ngBold" for="building_lat">Building Latitude:</label>
                    <input [(ngModel)]="latitude" id="building-lat" class="c-input" name="building-lat" type="text" />
                </div>
                <div class="buildingRow">
                    <label class="ngBold" for="building_long">Building Longitude:</label>
                    <input
                        [(ngModel)]="longitude"
                        id="building-long"
                        class="c-input"
                        name="building-long"
                        type="text"
                    />
                </div>
                <div class="buildingRowTop">
                    <span class="ngBold buildingRightMargin"> Address: </span>
                    <s25-ng-address [(model)]="addr" [readOnly]="false" [hasPhone]="false"></s25-ng-address>
                </div>
                <div class="buildingRowTop">
                    <label class="ngBold buildingRightMarginDes">Description:</label>
                    <s25-ng-rich-text-editor [(modelValue)]="building.description"></s25-ng-rich-text-editor>
                </div>
                <div class="buildingRowTop">
                    <label class="ngBold buildingRightMargin">Image:</label>
                    <span class="ngInlineBlock">
                        <s25-ng-editable-image
                            [showImage]="true"
                            [val]="building.imageId"
                            [onCommit]="this.imageAction"
                            (valueChange)="this.onChangeImage($event)"
                            autoSaveUpload="true"
                        ></s25-ng-editable-image>
                    </span>
                </div>
                @if (errorMsg) {
                    <div class="ngRed">{{ errorMsg }}</div>
                }
                <div class="buttons c-margin-top--half">
                    <button class="aw-button aw-button--primary c-margin-right--single" (click)="onSave()">Save</button>
                    <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
                </div>
            </div>
        }
    `,
    styles: `
        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }
        .buildingRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .buildingRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .buildingRow > input {
            min-width: 15em;
        }

        .buildingRowTop {
            display: flex;
            gap: 1em;
            align-items: top;
            margin: 0.5em 0;
        }

        .buildingRightMargin {
            margin-right: 6em;
        }

        .buildingRightMarginDes {
            margin-right: 4em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25BuildingComponent implements OnInit {
    @Input() item?: any;
    init: boolean = false;
    building: Building = {
        id: 0,
        buildingName: "",
        buildingCode: "",
        description: "",
        address: "",
        city: "",
        state: "",
        zipPostCode: "",
        country: "",
    };
    latitude: string;
    longitude: string;
    errorMsg = "";
    addr: AddressI = {};

    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        if (this.item) {
            this.building = S25Util.deepCopy(this.item);
            this.addr = {
                street: this.building.address,
                city: this.building.city,
                state: this.building.state,
                zip: this.building.zipPostCode,
                country: this.building.country,
            };
        }
        if (this.building.latitude !== undefined) this.latitude = this.building.latitude.toFixed(5).toString(); //input type is text, to dispaly consistent 5 decimals
        if (this.building.longitude !== undefined) this.longitude = this.building.longitude.toFixed(5).toString();
        this.init = true;
        this.cd.detectChanges();
    }

    validate() {
        this.errorMsg = "";
        const regex = /^-?\d+(\.\d{1,5})?$/;

        if (this.latitude && !regex.test(this.latitude)) {
            {
                this.errorMsg =
                    "Latitude is invalid. Please ensure value entered is numeric and does not exceed 5 decimal places.";
                this.cd.detectChanges();
                return false;
            }
        }

        if (this.longitude && !regex.test(this.longitude)) {
            {
                this.errorMsg =
                    "Longitude is invalid. Please ensure value entered is numeric and does not exceed 5 decimal places.";
                this.cd.detectChanges();
                return false;
            }
        }

        if (this.latitude) this.building.latitude = S25Util.parseFloat(this.latitude);
        if (this.longitude) this.building.longitude = S25Util.parseFloat(this.longitude);

        if (this.latitude || this.longitude) {
            if (
                !S25Util.isFloat(this.building.latitude) ||
                !S25Util.isFloat(this.building.longitude) ||
                !(this.building.latitude >= -90) ||
                !(this.building.latitude <= 90) ||
                !(this.building.longitude >= -180) ||
                !(this.building.longitude <= 180)
            ) {
                this.errorMsg = "Latitude or longitude is invalid.";
                this.cd.detectChanges();
                return false;
            }
        }

        if (!this.building.buildingName || this.building.buildingName.length < 2) {
            this.errorMsg = "A name longer than two characters is required";
            this.cd.detectChanges();
            return false;
        } else {
            return true;
        }
    }

    @Bind
    async onSave() {
        if (!this.validate()) return;
        this.errorMsg = "";
        if (this.latitude) this.building.latitude = S25Util.parseFloat(this.latitude);
        if (this.longitude) this.building.longitude = S25Util.parseFloat(this.longitude);

        let payload: Building = {
            buildingName: this.building.buildingName,
            buildingCode: this.building.buildingCode,
            latitude: this.building.latitude,
            longitude: this.building.longitude,
            description: this.building.description,
            address: this.addr.street,
            city: this.addr.city,
            state: this.addr.state,
            zipPostCode: this.addr.zip,
            country: this.addr.country,
            imageId: this.building.imageId,
        };

        let ok: any;
        if (this.item && this.item.id > 0) {
            payload.id = this.item.id;
            // console.log({ payload: payload });
            ok = await BuildingService.putBuilding(this.item.id, payload);
        } else {
            ok = await BuildingService.postBuilding(payload);
        }

        if (ok) this.saved.emit();
    }

    onCancel() {
        this.cancelled.emit();
    }

    imageAction = (data: any) => {
        this.building.imageId = data.imageId;
    };

    onImageChange(e: any) {
        //console.log({ e: e });
        this.building.imageId = e;
        this.cd.detectChanges();
    }
}
